<template>
  <div class="limiter">

    <TopNav :kindTheme="0"/>

    <div class="container-login100"
      :style="`background-image: url('${imageDomain}/assets/img/agency-login-back.jpg?speedsize=w_1280');`">
      <div class="wrap-login100 pt-190 pb-30" v-if="odysseyAgentState || isFcAgentMarketerMode">
        <div class="login100-form-avatar">
          <img :src="srcLogo" :alt="agencyName" width="120">
        </div>

        <span class="login100-form-title pt-20 pb-45" v-if="isFcAgentMarketerMode">
          {{ agencyName }}
        </span>

        <div class="wrap-input100 mb-3" v-if="!isAgencyAgentLogin && !isFcAgentMarketerMode">
          <b-form-input type="text" class="input100" :state="!!odyAgentCode" placeholder="Odyssey Agent Code"
            v-model="odyAgentCode"></b-form-input>
          <span class="symbol-input100">
            <i class="fa fa-id-card"></i>
          </span>
        </div>
        <div class="wrap-input100 mb-3" v-if="!isAgencyAgentLogin">
          <b-form-input type="text" class="input100" :state="!!clerkName" placeholder="Clerk Name"
            v-model="clerkName"></b-form-input>
          <span class="symbol-input100">
            <i class="fa fa-user"></i>
          </span>
        </div>

        <div class="wrap-input100 mb-10" v-if="!isAgencyAgentLogin">
          <b-form-input type="password" class="input100" @keypress="pressEnter($event)" :state="!!password"
            placeholder="Password" v-model="password"></b-form-input>
          <span class="symbol-input100">
            <i class="fa fa-lock"></i>
          </span>
        </div>

        <div class="text-center w-full error-message" v-if="errorMessage">
          <!-- {{ errorMessage }} -->
          {{ $t("login.login-error-message") }}
        </div>

        <div class="container-login100-form-btn mt-3">
          <b-button class="login100-form-btn" @click="requestLogin">
            {{ $t("login.odyssey-login") }}
          </b-button>
        </div>

        <div class="container-login100-form-btn mt-3">
          <b-button class="login100-form-btn" @click="cancelLogin">
            {{ $t("login.cancel") }}
          </b-button>
        </div>

        <!-- <div class="text-center w-full">
          <a class="txt1" href="#">
            Create new account
            <i class="fa fa-long-arrow-right"></i>
          </a>
        </div> -->
      </div>
      <div class="wrap-login100 pt-190 pb-30" v-else-if="isLoginMarketer && preferSmsToPass">
        <div class="login100-form-avatar">
          <img :src="srcLogo" :alt="agencyName" width="120">
        </div>

        <span class="login100-form-title pt-20 pb-45">
          {{ agencyName }}
        </span>

        <div class="wrap-input100 mb-3" v-if="agencySid && !isAgencyAgentLogin">
          <b-form-input type="text" class="input100" @keypress="pressEnterSms($event)" :state="!!verifyCode"
            placeholder="Code" v-model="verifyCode"></b-form-input>
          <span class="symbol-input100">
            <i class="fa fa-lock"></i>
          </span>
        </div>
        <div class="text-center w-full error-message" v-if="errorMessage">
          <!-- {{ errorMessage }} -->
          {{ $t("login.login-error-message") }}
        </div>

        <div class="container-login100-form-btn pt-3" v-if="!agencySid">
          <b-button class="login100-form-btn" @click="requestLogin">
            {{ $t("login.agency-login") }}
          </b-button>
        </div>

        <span v-else-if="!isAgencyAgentLogin" class="mt-3">
          <div class="container-login100-form-btn">
            <b-button class="login100-form-btn" @click="requestVerifyCode">
              {{ $t("login.agency-verify-sms") }}
            </b-button>
          </div>

          <div class="text-center w-full pt-25 pb-230">
            <p class="txt1">
              {{ $t("login.verify-code-message") }}
            </p>
          </div>
        </span>
        <div class="container-login100-form-btn mt-3">
          <b-button class="login100-form-btn" @click="cancelLogin">
            {{ $t("login.cancel") }}
          </b-button>
        </div>
      </div>
      <div class="wrap-login100 pt-190 pb-30" v-else-if="isLoginMarketer && !preferSmsToPass && !isAgencyAgentLogin">
        <div class="login100-form-avatar mb-4">
          <img :src="srcLogo" :alt="agencyName" width="120" height="">
        </div>

        <span class="login100-form-title pt-20 pb-45" v-if="!isMenoraAgency">
          {{ agencyName }}
        </span>

        <div class="text-center w-full maccabi-enter-number" v-if="isMaccabiAgency">
          <span v-if="isRIBBONAgency">{{ $t("login.enter-password") }}</span>
          <span v-else-if="is1MAMISHAgency">{{ $t("login.enter-voucher-code") }}</span>
          <span v-else-if="isMenoraAgency">{{ $t("login.enter-employee-menora-number") }}</span>
          <span v-else-if="isFCLMEITAgency">{{ $t("login.enter-fclmeit-ID") }}</span>
          <span v-else-if="is1MaccabiFCLSHAVAgency">{{ $t("login.enter-ID") }}</span>
          <span v-else>{{ $t("login.enter-employee-number") }}</span>
          <!-- {{ $t("login.places-sold-out") }} -->
        </div>

        <div class="wrap-input100 mb-3" v-if="!agencySid && !isAgencyAgentLogin">
          <b-form-input type="password" class="input100" @keypress="pressEnterPass($event)" :state="!!marketerPassword"
            :placeholder="isHAIPORTAgency ? $t('login.enter-employee-number') : 'Password'" v-model="marketerPassword"></b-form-input>
          <span class="symbol-input100">
            <i class="fa fa-lock"></i>
          </span>
        </div>
        <div class="text-center w-full error-message" v-if="errorMessage">
          <!-- {{ errorMessage }} -->
          {{ $t("login.login-error-message") }}
        </div>

        <span v-if="!isAgencyAgentLogin" class="mt-3">
          <div class="container-login100-form-btn">
            <b-button class="login100-form-btn" @click="requestWithMarketerPassword">
              <span v-if="isHAIPORTAgency">{{ $t("calendar.continue") }}</span>
              <span v-else-if="isMaccabiAgency">{{ $t("login.maccabi-login") }}</span>
              <span v-else>{{ $t("login.agency-verify-password") }}</span>
            </b-button>
          </div>
        </span>
        <div class="container-login100-form-btn mt-3" v-if="!isMaccabiAgency && !isHAIPORTAgency">
          <b-button class="login100-form-btn" @click="cancelLogin">
            {{ $t("login.cancel") }}
          </b-button>
        </div>

      </div>
      <div class="w-100">
        <img :src="bannerImage" v-if="isEINAVBUAgency" class="EINAVBU-banner img-fluid" width="3387" height="466"/>
        <ChooseDestForMaccabi
          v-if="isMaccabiAgency && destListShowCondition && maccabiDeals && maccabiDeals.length"
          :deals="maccabiDeals"/>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { BButton, BFormInput } from 'bootstrap-vue';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'agency-agent-login',
  mixins: [imageUrlMixin],
  components: {
    BButton,
    BFormInput,
    TopNav: () => import('@/components/HeaderFooter/TopNav/TopNav'),
    ChooseDestForMaccabi: () => import('@/components/home/ChooseDestForMaccabi'),
  },
  data() {
    return {
      odyAgentCode: '',
      verifyCode: '',
      clerkName: '',
      password: '',
      marketerPassword: '',
    };
  },
  computed: {
    ...mapState({
      isCategoryLinkAgency: (state) => state.agencyUsers.isCategoryLinkAgency,
      isEINAVBUAgency: (state) => state.agencyUsers.isEINAVBUAgency,
      isHAIPORTAgency: (state) => state.agencyUsers.isHAIPORTAgency,
    }),
    ...mapGetters({
      device: 'GET_DEVICE',
      isAgencyAgentLogin: 'IS_AGENCY_AUTHORIZED',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      errorMessage: 'GET_AGENCY_ERROR_MESSAGE',
      agencySid: 'GET_AGENCY_SID',
      odysseyAgentState: 'GET_ODYSSEY_AGENT_STATE',
      odysseyAgentContent: 'GET_ODYSSEY_AGENT_CONTENT',
      // marketer login part
      isLoginMarketer: 'GET_IS_LOGIN_MARKETER',
      preferSmsToPass: 'GET_PREFER_SMS_PASS',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
      is1MaccabiFCLSHAVAgency: 'GET_IS_1_MACCABI_AGENCY',
      is1MAMISHAgency: 'GET_IS_1MAMISH_AGENCY',
      isMenoraAgency: 'GET_IS_MENORA_AGENCY',
      isRIBBONAgency: 'GET_IS_RIBBON_AGENCY',
      isFCLMEITAgency: 'GET_IS_FCLMEIT_AGENCY',
      marketerId: 'GET_MARKETER_ID',
      dealData: 'GET_DEAL_DATA',
    }),
    bannerImage() {
      return this.device === 'desktop' ? `${this.imageDomain}/assets/img/EINAVBU-banner/EINAVBU-main-desktop.png`
        : `${this.imageDomain}/assets/img/EINAVBU-banner/EINAVBU-main-mobile.png`;
    },
    srcLogo() {
      const { marketerAgencyContent, odysseyAgentState, odysseyAgentContent } = this;
      let logo = '';
      if (odysseyAgentState) {
        logo = odysseyAgentContent.logoUrls[0];
      } else {
        logo = (marketerAgencyContent === null) ? this.whiteLabel.logoUrls[0] : marketerAgencyContent.logoUrls[0];
      }
      return logo;
    },
    agencyName() {
      const { marketerAgencyContent } = this;
      return (marketerAgencyContent === null) ? this.whiteLabel.name_Loc.content : marketerAgencyContent.name_Loc.content;
    },
    destListShowCondition() {
      const { isAgencyAgentLogin, isCategoryLinkAgency, isEINAVBUAgency } = this;
      return isAgencyAgentLogin || isCategoryLinkAgency || isEINAVBUAgency;
    },
    maccabiDeals() {
      // const showedDests = ['dxb', 'tiv', 'bdv', 'buh'];
      const { dealData } = this;

      if (this.destListShowCondition) {
        return dealData && dealData.length
          ? dealData.filter(
            (deal) => deal.agencyCode === this.marketerId,
            // && showedDests.includes(deal.destinationCode.toLowerCase())
          )
          : [];
      }
      return [];
    },
  },
  watch: {
    verifyCode() {
      this.$store.commit('SET_AGENCY_VERIFY_CODE', this.verifyCode);
    },
    agencySid() {
      this.verifyCode = '';
    },
  },
  methods: {
    async requestLogin() {
      if (!this.isFcAgentMarketerMode && this.odysseyAgentState && (this.clerkName && this.password && this.odyAgentCode)) {
        await this.$store.dispatch('REQUEST_AGENCY_LOGIN', { odyAgentCode: this.odyAgentCode, clerkName: this.clerkName, password: this.password });
      } else if (this.isFcAgentMarketerMode) {
        await this.$store.dispatch('REQUEST_AGENCY_LOGIN', { clerkName: this.clerkName, password: this.password });
      } else {
        await this.$store.dispatch('REQUEST_AGENCY_LOGIN');
      }
    },
    async requestVerifyCode() {
      await this.$store.dispatch('REQUEST_AGENCY_BY_VERIFY_CODE');
    },
    async requestWithMarketerPassword() {
      await this.$store.dispatch('REQUEST_MARKETER_LOGIN_BY_PASSWORD', { password: this.marketerPassword });
    },
    async cancelLogin() {
      if (this.isAgencyAgentLogin) await this.$store.dispatch('REQUEST_AGENCY_LOGOUT');
      if (this.odysseyAgentState) {
        window.location.href = process.env.VUE_APP_MAIN_DOMAIN;
      } else {
        window.location = '/';
      }
    },
    pressEnter(event) {
      if (event.charCode === 13) {
        this.requestLogin();
      }
    },
    pressEnterSms(event) {
      if (event.charCode === 13) {
        this.requestVerifyCode();
      }
    },
    pressEnterPass(event) {
      if (event.charCode === 13) {
        this.requestWithMarketerPassword();
      }
    },
  },
};
</script>

<style scoped>
img.EINAVBU-banner {
  margin-top: 5.5rem;
}
.maccabi-enter-number {
  font-size: 1.5rem;
  color: white;
}

a {
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #fff;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #999999;
}

input:-moz-placeholder {
  color: #999999;
}

input::-moz-placeholder {
  color: #999999;
}

input:-ms-input-placeholder {
  color: #999999;
}

textarea::-webkit-input-placeholder {
  color: #999999;
}

textarea:-moz-placeholder {
  color: #999999;
}

textarea::-moz-placeholder {
  color: #999999;
}

textarea:-ms-input-placeholder {
  color: #999999;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-size: 16px;
  color: #cccccc;
  line-height: 1.4;
}

.error-message {
  color: #fb7575;
  text-shadow: 1px 1px black;
  font-size: 1.5rem;
}

.bo1 {
  border-bottom: 1px solid #999999;
}

.hov1:hover {
  border-color: #d33f8d;
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  position: relative;
  z-index: 1;
}

.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #005bea;
  background: -webkit-linear-gradient(to bottom, #005bea, #00c6fb);
  background: -o-linear-gradient(to bottom, #005bea, #00c6fb);
  background: -moz-linear-gradient(to bottom, #005bea, #00c6fb);
  background: linear-gradient(to bottom, #005bea, #00c6fb);
  opacity: 0.9;
}

.wrap-login100 {
  width: 390px;
  background: transparent;
}

/*------------------------------------------------------------------
[  ]*/
.login100-form {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.login100-form-title {
  font-size: 24px;
  color: #fff;
  line-height: 1.2;
  text-align: center;

  width: 100%;
  display: block;
}

/*---------------------------------------------*/
.login100-form-avatar {
  width: 220px;
  height: auto;
  /* border-radius: 50%; */
  overflow: hidden;
  margin: 0 auto;
}

.login100-form-avatar img {
  width: 100%;
}

/*---------------------------------------------*/
.wrap-input100 {
  position: relative;
  width: 100%;
  z-index: 1;
}

.input100 {
  font-size: 15px;
  line-height: 1.2;
  color: #333333;

  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  padding: 0 53px 0 53px !important;
  border-style: solid;
  border-width: 2px;
  background-position: left calc(0.375em + 0.5rem) center;
}

/*------------------------------------------------------------------
[ Focus ]*/
.focus-input100 {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(0, 91, 234, 0.6);
}

.input100:focus+.focus-input100 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

.symbol-input100 {
  font-size: 15px;
  color: #999999;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-right: 30px;
  pointer-events: none;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus+.focus-input100+.symbol-input100 {
  color: #00c6fb;
  padding-right: 23px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  font-size: 15px;
  line-height: 1.5;
  color: #e0e0e0;

  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  position: relative;
  z-index: 1;
}

.login100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  top: 0;
  left: 0;
  background: #005bea;
  background: -webkit-linear-gradient(to left, #005bea, #00c6fb);
  background: -o-linear-gradient(to left, #005bea, #00c6fb);
  background: -moz-linear-gradient(to left, #005bea, #00c6fb);
  background: linear-gradient(to left, #005bea, #00c6fb);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  opacity: 0;
}

.login100-form-btn:hover {
  background: transparent;
  color: #fff;
}

.login100-form-btn:hover:before {
  opacity: 1;
}

/*------------------------------------------------------------------
[ Button sign in with ]*/
.btn-face,
.btn-google {
  font-size: 16px;
  line-height: 1.2;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc((100% - 10px) / 2);
  height: 40px;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  background-color: #fff;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.btn-face {
  color: #3b5998;
}

.btn-face i {
  font-size: 20px;
  margin-right: 10px;
  padding-bottom: 1px;
}

.btn-google {
  color: #555555;
}

.btn-google img {
  width: 19px;
  margin-right: 10px;
  padding-bottom: 1px;
}

.btn-face:hover,
.btn-google:hover {
  border-color: #d33f8d;
}

@media (max-width: 576px) {
  .wrap-login100 {
    padding-top: 80px;
    padding-left: 15px;
    padding-right: 15px;
  }
  img.EINAVBU-banner {
    margin-top: 0;
  }
}
@media (max-width:479px){
  .container-login100 {
    min-height: auto;
  }
img.EINAVBU-banner {
  margin-top: 0;
}
}
</style>
